import Stores from '@/stores';
import apolloClient from '@/plugins/apollo';
import {
  QUERY_APPLICATIONS_SUBSTITUTE,
  QUERY_BOOKMARKS_SUBSTITUTE,
  QUERY_SUBSTITUTE,
  QUERY_SUBSTITUTE_INSURANCES,
  QUERY_SERVICE_SERVICEMANAGEMENT_SUBSTITUTE
} from '@/data/gql/queries';
import {
  MOBILE_SERVICES_QUERY,
  OFFICE_SERVICES_QUERY
} from '@/data/gql/misc_queries';
import {
  UPDATE_SUBSTITUTE,
  UPDATE_ADDRESSES,
  UPDATE_BANKACCOUNTS,
  UPDATE_SPECIALIZATION,
  REQUEST_SUBSTITUTE,
  ADD_PAYMENT_METHOD_BILL,
  ADD_SUBSTITUTE_SPECIALIZATION,
  UPDATE_SERVICE,
  UPDATE_SUBSTITUTE_BLACKLIST
} from '@/data/gql/mutations';

import EventBus from '@/services/EventBus';
import Service from '@/definitions/interfaces/Service.i';
import Bookmark from '@/definitions/interfaces/Bookmark.i';
import Application from '@/definitions/interfaces/Application.i';
import Address from '@/definitions/interfaces/Address.i';
import BankAccount from '@/definitions/interfaces/BankAccount.i';
import SubstituteInterface from '@/definitions/interfaces/Substitute.i';
import LiabilityInsurance from '@/definitions/interfaces/LiabilityInsurance.i';
import ProfileCompleteness from '@/definitions/interfaces/ProfileCompletenessScore.i';
import Sort from '@/utils/Sort';
import Upload from '@/utils/Upload';
import DateTime from '@/utils/DateTime';
import Debug from '@/utils/Debug';
import CaptureException from '@/services/CaptureException';

import { $t } from '@/plugins/i18n';

class Substitute {
  /**
   * fetch substitute data set
   * @param {boolean} alsoFetchServices
   * @return {Promise}
   */
  public async fetchSubstitute(alsoFetchServices: boolean): Promise<void> {
    Stores.substituteData.loadingSubstitute = true;

    try {
      const _substitute = await apolloClient.defaultClient.query({
        query: QUERY_SUBSTITUTE,
        fetchPolicy: 'no-cache'
      });

      Stores.substituteData.substitute = _substitute.data.profile.substitute;
      Stores.substituteData.liabilityInsurances = _substitute.data.profile.substitute.liabilityInsurances;
      Stores.substituteData.debtor = _substitute.data.profile.substitute.debtor;
      Stores.substituteData.blackListedStates = _substitute.data.profile.substitute.blackListedStates;

      if (alsoFetchServices) {
        this.fetchApplications();
        this.fetchAssignments();
        this.fetchBookmarks();
      }

      await this.filterPlaceholders();
    }
    catch(e) {
      CaptureException.send(e);
    }

    Stores.substituteData.loadingSubstitute = false;
    EventBus.emit(EventBus.keys.SUBSTITUTE_LOAD_COMPLETE);
  }

  /**
   * while setting up the account we might have set some placeholders;
   * this method cleans those from the profile, so the user doesn't see them
   *
   */
  async filterPlaceholders(): Promise<void> {
    for (const key of Object.keys(Stores.substituteData.substitute)) {
      if (Stores.substituteData.substitute[key] === '####PLACEHOLDER####' || Stores.substituteData.substitute[key] === '0') {
        Stores.substituteData.substitute[key] = null;
      }
    }
    for (const key of Object.keys(Stores.substituteData.substitute.debtor.billingAddress)) {
      if (Stores.substituteData.substitute.debtor.billingAddress[key] === '####PLACEHOLDER####' || Stores.substituteData.substitute.debtor.billingAddress[key] === '0') {
        Stores.substituteData.substitute.debtor.billingAddress[key] = null;
      }
    }
    for (const key of Object.keys(Stores.substituteData.substitute.address)) {
      if (Stores.substituteData.substitute.address[key] === '####PLACEHOLDER####' || Stores.substituteData.substitute.address[key] === '0') {
        Stores.substituteData.substitute.address[key] = null;
      }
    }
    for (const key of Object.keys(Stores.substituteData.substitute.bankAccount)) {
      if (Stores.substituteData.substitute.bankAccount[key] === '####PLACEHOLDER####' || Stores.substituteData.substitute.bankAccount[key] === '0') {
        Stores.substituteData.substitute.bankAccount[key] = null;
      }
    }
  }

  /**
   * calculate the profile completness and return attributes to complete
   * @return {ProfileCompleteness}
   */
    getProfileCompleteness(): ProfileCompleteness {
      const _score: ProfileCompleteness = {
        score: 100,
        reasons: []
      };
      if (Stores.substituteData.substitute) {
        for (const key of Object.keys(Stores.substituteData.substitute)) {
          if((key === 'specializations' && Stores.substituteData.substitute.specializations.length === 0) ||
            (key === 'trainingConfirmed' && !Stores.substituteData.substitute.trainingConfirmed) ||
            (key === 'license' && Stores.substituteData.substitute[key] === null)) {
            _score.score -=5;
            _score.reasons.push({label: key.toUpperCase(), route: '/profile/specialization'});
          } else if (Stores.substituteData.substitute[key] === null) {
            _score.score -=5;
            _score.reasons.push({label: key.toUpperCase(), route: '/profile/basedata'});
          }
        }
        for (const key of Object.keys(Stores.substituteData.substitute.debtor.billingAddress)) {
          if (Stores.substituteData.substitute.debtor.billingAddress[key] === null) {
            _score.score -=10;
            _score.reasons.push({label: 'BILLING_ADDRESS', route: '/profile/billing'});
            break;
          }
        }
        for (const key of Object.keys(Stores.substituteData.substitute.bankAccount)) {
          if (Stores.substituteData.substitute.bankAccount[key] === null) {
            _score.score -=10;
            _score.reasons.push({label: 'BANK_ACCOUNT', route: '/profile/billing'});
            break;
          }
        }
      }
      if(Stores.substituteData.liabilityInsurances.length === 0) {
          _score.score -=10;
          _score.reasons.push({label: 'NO_INSURANCE', route: '/profile/insurances'});
      } else {
        const _today = new Date();
        if(!Stores.substituteData.liabilityInsurances.some((insurance: LiabilityInsurance) => new Date(insurance.validTo) > _today)) {
          _score.score -= 10;
          _score.reasons.push({label: 'NO_VALID_INSURANCE', route: '/profile/insurances'});
        };
      }
      if (_score.score < 0) {
        _score.score = 20;
      }
      return _score;
    }

  /**
   * returns an array of Services that need the focus of the substitute user
   * services are taken from local Storage
   *
   * @param {number} count - define how many services should be returned
   * @return {Array.<Service>}
   *
   */
  public getFocusAssignments(count: number|null): Service[] {
    const _focusAssignments = Stores.substituteData.focusAssignments;
    const _count = count ? count : _focusAssignments.length;
    return _focusAssignments.slice(0,_count);
  }

  /**
   * returns an array of Services that are on the watchlist of the substitute user
   * services are taken from local Storage, set to null for all
   *
   * @param {number|null} count - define how many services should be returned
   * @return {Array.<Service|Bookmark>}
   *
   */
    public getBookmarksAsServices(count: number|null): Service[] {
      const _bookmarks = Stores.substituteData.bookmarks;
      const _count = count ? count : _bookmarks.length;
      const _services: Service[] = [];
      for (const bookmark of _bookmarks) {
        _services.push(bookmark.details);
      }
      return _services.slice(0, _count);
    }

  /**
   * returns an array of Services that are on the watchlist of the substitute user
   * services are taken from local Storage, set to null for all
   *
   * @param {number|null} count - define how many services should be returned
   * @return {Array.<Service|Bookmark>}
   *
   */
    public getBookmarksAsBookmarks(count: number|null): Bookmark[] {
      const _bookmarks = Stores.substituteData.bookmarks;
      const _count = count ? count : _bookmarks.length;
      return _bookmarks.slice(0, _count);
    }

  /**
   *  returns an array of Assignments from the local storage
   *
   * @param {number} count - define how many services should be returned, set to null for all
   * @return {Array.<Service>}
   *
   */
  public getAssignments(count: number|null): Service[] {
    const _assignments = Stores.substituteData.assignments;
    const _count = count ? count : _assignments.length;

    return _assignments.slice(0, _count);
  }

  /**
   *  returns an array of Applications from the local storage
   *
   * @param {number} count - define how many services should be returned, set to null for all
   * @return {Array.<Application|Service>}
   *
   */
  public getApplicationsAsServices(count: number|null): Service[] {
    const _applications = Stores.substituteData.applications;
    const _count = count ? count : _applications.length;
    const _services: Service[] = [];
    for (const application of _applications) {
      _services.push(application.details);
    }
    return _services.slice(0, _count);
  }

    /**
   *  returns an array of Applications from the local storage
   *
   * @param {number} count - define how many services should be returned, set to null for all
   * @return {Array.<Application|Service>}
   *
   */
  public getApplicationsAsApplications(count: number|null): Application[] {
    const _applications = Stores.substituteData.applications;
    const _count = count ? count : _applications.length;
    return _applications.slice(0, _count);
  }

  /**
   * update the address in the backend
   * @param {Address} newAddress
   * @return {Promise.<boolean>}
   */
  public async updateAddress(newAddress: Address): Promise<boolean> {
    const _response = await apolloClient.defaultClient
      .mutate({
        mutation: UPDATE_ADDRESSES,
        variables: {
          addresses: [
            {
              id: newAddress.id,
              city: newAddress.city,
              street: newAddress.street,
              houseNr: newAddress.houseNr,
              zipCode: newAddress.zipCode,
              state: newAddress.state,
              country: newAddress.country,
            },
          ]
        }
    });
    return _response.data.updateAddresses;
  }

  /**
   * update the banking informion in the store and in the backend
   * @param {BankAccount} newBankAccount
   * @return {Promise.<boolean>}
   */
  public async updateBankAccount(newBankAccount: BankAccount): Promise<boolean> {
    const _response = await apolloClient.defaultClient
      .mutate({
        mutation: UPDATE_BANKACCOUNTS,
        variables: {
          accounts: [
            {
              id: newBankAccount.id,
              accountHolder: newBankAccount.accountHolder,
              bank: newBankAccount.bank,
              iban: newBankAccount.iban,
              bic: newBankAccount.bic,
            },
          ]
        }
    });
    return _response.data.updateBankAccounts;
  }

  /**
   * update the substitute address and bank account
   * @param {Address} address
   * @param {BankAccount} bankAccount
   * @param {boolean} alsoReloadFromDB
   * @return {Promise.<boolean>}
   */
  public async updateSubstituteBilling(newAddress: Address, bankAccount: BankAccount, alsoReloadFromDB: boolean): Promise<void> {
    Stores.substituteData.address = newAddress;
    Stores.substituteData.bankAccount = bankAccount;
    const _billingAddress: Address = Stores.substituteData.debtor.billingAddress;
    _billingAddress.city = newAddress.city;
    _billingAddress.street = newAddress.street;
    _billingAddress.houseNr = newAddress.houseNr;
    _billingAddress.state = newAddress.state;
    _billingAddress.zipCode = newAddress.zipCode;
    await this.updateAddress(Stores.substituteData.address as unknown as Address);
    await this.updateAddress(Stores.substituteData.debtor.billingAddress as unknown as Address);
    await this.updateBankAccount(Stores.substituteData.bankAccount as unknown as BankAccount);
    if (alsoReloadFromDB) {
      const _response = this.fetchSubstitute(false);
      return _response;
    }
  }

  /**
   * update the substitute
   * @param {SubstituteInterface} newSubstitute
   * @return {Promise.<boolean>}
   */
  public async updateSubstitute(newSubstitute: SubstituteInterface): Promise<boolean> {
    const _response = await apolloClient.defaultClient
      .mutate({
        mutation: UPDATE_SUBSTITUTE,
        variables: {
          substituteID: Stores.substituteData.substitute.id,
          trainingConfirmed: newSubstitute.trainingConfirmed,
          taxNumber: newSubstitute.taxNumber,
          has_doctors_bag: newSubstitute.has_doctors_bag,
          use_default_contract: newSubstitute.use_default_contract,
        }
    });
    if (_response.data.updateSubstitute == true) {
      await this.fetchSubstitute(false);
      return true;
    }
    else {
      return false;
    }
  }


  /** fetch insurances
   * @return {Promise}
   */
  async fetchInsurances(): Promise<void> {
    Stores.substituteData.loadingInsurances = true;
    try {
      const _insurances = await apolloClient.defaultClient.query({
        query: QUERY_SUBSTITUTE_INSURANCES,
        fetchPolicy: 'no-cache'
      });
      Stores.substituteData.liabilityInsurances = _insurances.data.profile.substitute.liabilityInsurances;
    }
    catch(e) {
      CaptureException.send(e);
    }
    Stores.substituteData.loadingInsurances = false;
    EventBus.emit(EventBus.keys.SUBSTITUTE_LOAD_INSURANCES_COMPLETE);
  }

  /**
   * create a new insurance
   * @param {string} insuranceName
   * @param {string} insuranceNumber
   * @param {Date} validFrom
   * @param {Date} validTo
   * @param {File} insurance_police
   * @return {Promise.<boolean>}
   */
  public async addLiabilityInsurance(insuranceName: string|null, insuranceNumber: string|null, validFrom: Date|null, validTo: Date|null, insurancePolice: File|null, fromWizard: boolean = false): Promise<boolean> {
    EventBus.emit(EventBus.keys.UPDATE_SETUP_STEP, 'SETUP_SUBSTITUTE_INSURANCE');
    if(fromWizard) {
      if(Stores.wizard.substitute.liabilityInsuranceName !== null &&
        Stores.wizard.substitute.liabilityInsuranceName.length > 0 &&
        Stores.wizard.substitute.liabilityInsuranceNumber !== null &&
        Stores.wizard.substitute.liabilityInsuranceNumber.length > 0 &&
        Stores.wizard.substitute.liabilityInsuranceValidityFrom !== null &&
        Stores.wizard.substitute.liabilityInsuranceValidityTo !== null &&
        Stores.wizard.substitute.liabilityInsurance !== null &&
        Stores.wizard.substitute.liabilityInsurance.length > 0) {
          const _response = await apolloClient.defaultClient
          .mutate({
            mutation: UPDATE_SUBSTITUTE,
            variables: {
              substituteID: Stores.substituteData.substitute.id,
              liabilityInsurance: {
                insuranceName: Stores.wizard.substitute.liabilityInsuranceName,
                insuranceNumber: Stores.wizard.substitute.liabilityInsuranceNumber,
                validFrom: DateTime.dateToIsoDateString(Stores.wizard.substitute.liabilityInsuranceValidityFrom),
                validTo: DateTime.dateToIsoDateString(Stores.wizard.substitute.liabilityInsuranceValidityTo),
                document: {
                  filename: Stores.wizard.substitute.liabilityInsuranceFilename,
                  url: Stores.wizard.substitute.liabilityInsurance
                },
              }
            },
            context: {
              hasUpload: true
            }
        });
        if (_response.data.updateSubstitute == true) {
          await this.fetchInsurances();
          return true;
        }
      } else {
        return false;
      }
    } else if(validFrom && validTo && insurancePolice) {
      const _response = await apolloClient.defaultClient
        .mutate({
          mutation: UPDATE_SUBSTITUTE,
          variables: {
            substituteID: Stores.substituteData.substitute.id,
            liabilityInsurance: {
              insuranceName: insuranceName,
              insuranceNumber: insuranceNumber,
              validFrom: DateTime.dateToIsoDateString(validFrom),
              validTo: DateTime.dateToIsoDateString(validTo),
              document: await Upload.getUploadData(insurancePolice),
            }
          },
          context: {
            hasUpload: true
          }
      });
      if (_response.data.updateSubstitute == true) {
        await this.fetchInsurances();
        return true;
      }
      else {
        return false;
      }
    }
    return false;
  }


  /**
   * get a current download link for the given insurance
   * @params {LiabilityInsurance} insurance
   * @return {Promise.<string>} - the url for the download
   */
  public async getInsuranceDownloadLink(insurance: LiabilityInsurance): Promise<string> {
    await this.fetchInsurances();
    const _insuranceIndex: number = Stores.substituteData.liabilityInsurances.findIndex((i: LiabilityInsurance) => i.id === insurance.id);
    if (_insuranceIndex !== -1) {
      return Stores.substituteData.liabilityInsurances[_insuranceIndex].document.url;
    }
    else {
      return '';
    }
  }

  /**
   * create a new license
   * @param {File|null} license file
   * @param {boolean} fromWizard - construct the upload from the wizard storage
   * @return {Promise.<boolean>}
   */
  public async addLicense(license: File|null, fromWizard: boolean): Promise<boolean> {
    if (fromWizard) {
      EventBus.emit(EventBus.keys.UPDATE_SETUP_STEP, 'SETUP_SUBSTITUTE_LICENSE');

      const _response = await apolloClient.defaultClient
        .mutate({
          mutation: UPDATE_SUBSTITUTE,
          variables: {
            substituteID: Stores.substituteData.substitute.id,
            license: {
              filename: Stores.wizard.substitute.licenseToPracticeFilename,
              url: Stores.wizard.substitute.licenseToPractice,
            },
          },
          context: {
            hasUpload: true
          }
      });
      if (_response.data.updateSubstitute == true) {
        await this.fetchSubstitute(false);
        return true;
      }
      else {
        return false;
      }
    }
    else {
      if (license !== null) {
        const _response = await apolloClient.defaultClient
          .mutate({
            mutation: UPDATE_SUBSTITUTE,
            variables: {
              substituteID: Stores.substituteData.substitute.id,
              license: await Upload.getUploadData(license),
            },
            context: {
              hasUpload: true
            }
        });
        if (_response.data.updateSubstitute == true) {
          await this.fetchSubstitute(false);
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
  }

  /**
   * add a Specialization
   * @param {string} specialization
   * @return {Promise.<boolean>}
   */
  public async addSpecialization(specialization: string): Promise<boolean> {
    const _response = await apolloClient.defaultClient
      .mutate({
        mutation: ADD_SUBSTITUTE_SPECIALIZATION,
        variables: {
          specializations: [
            {
              specialization: specialization,
            }
          ],
        }
    });
    if (_response.data.addSubstituteSpecializations == true) {
      await this.fetchSubstitute(false);
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * update blacklist information for service mass mailings
   * @return {Promise.<boolean>}
   */
  public async updateBlacklist(): Promise<boolean> {
    const _response = await apolloClient.defaultClient
      .mutate({
        mutation: UPDATE_SUBSTITUTE_BLACKLIST,
        variables: {
          substituteID: Stores.substituteData.substitute.id,
          states: Stores.substituteData.blackListedStates,
          byUser: true,
        }
    });
    if (_response.data.updateSubstituteServiceNewsletterBlacklist == true) {
      await this.fetchSubstitute(false);
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * update the certificate to the specialization
   * @param {File|null} certificate file
   * @param {boolean} fromWizard - construct the upload from the wizard storage
   * @return {Promise.<boolean>}
   */
  public async updateSpecializationCertificate(certificate: File|null, fromWizard: boolean): Promise<boolean> {
    if (fromWizard) {
      if (Stores.substituteData.substitute.specializations.length > 0) {
        const _response = await apolloClient.defaultClient
          .mutate({
            mutation: UPDATE_SPECIALIZATION,
            variables: {
              id: Stores.substituteData.substitute.specializations[0].id,
              certificate: {
                filename: Stores.wizard.substitute.licenseToPracticeFilename,
                url: Stores.wizard.substitute.licenseToPractice,
              },
            },
            context: {
              hasUpload: true
            }
        });
        if (_response.data.updateSubstituteSpecializations == true) {
          await this.fetchSubstitute(false);
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
    else {
      if (certificate !== null) {
        if (Stores.substituteData.substitute.specializations.length > 0) {
          const _response = await apolloClient.defaultClient
            .mutate({
              mutation: UPDATE_SPECIALIZATION,
              variables: {
                id: Stores.substituteData.substitute.specializations[0].id,
                certificate: await Upload.getUploadData(certificate)
              },
              context: {
                hasUpload: true
              }
          });
          if (_response.data.updateSubstitute == true) {
            await this.fetchSubstitute(false);
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
  }

  public async updateTrainingConfirmed(trainingConfirmedFlag: boolean): Promise<boolean> {
    const _response = await apolloClient.defaultClient
      .mutate({
        mutation: UPDATE_SUBSTITUTE,
        variables: {
          substituteID: Stores.substituteData.substitute.id,
          trainingConfirmed: trainingConfirmedFlag,
        }
    });

    return _response.data.updateSubstitute;
  }

  /**
   * Request a substitute role from the backend
   * @param {boolean} trainingConfirmed
   * @param {string} taxNumber
   * @param {Address} address
   * @param {BankAccount} bankAccount
   * @return {Promise.<boolean>}
   */
  public async requestSubstituteRole(trainingConfirmed: boolean, taxNumber: string, address: Address, bankAccount: BankAccount): Promise<boolean> {
    EventBus.emit(EventBus.keys.UPDATE_SETUP_STEP, 'SETUP_SUBSTITUTE');
    const _response = await apolloClient.defaultClient
      .mutate({
        mutation: REQUEST_SUBSTITUTE,
        variables: {
          trainingConfirmed: trainingConfirmed,
          taxNumber: taxNumber,
          address: {
            city: address.city,
            street: address.street,
            houseNr: address.houseNr,
            zipCode: address.zipCode,
            state: address.state,
            country: address.country,
          },
          billingAddress: {
            city: address.city,
            street: address.street,
            houseNr: address.houseNr,
            zipCode: address.zipCode,
            state: address.state,
            country: address.country,
          },
          bankAccount: {
            accountHolder: bankAccount.accountHolder,
            bank: bankAccount.bank,
            iban: bankAccount.iban,
            bic: bankAccount.bic,
          },
        }
    });
    if (_response.data.requestSubstituteRole === 'granted') {
      await this.fetchSubstitute(false);
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * Add a debtor bill payment method
   * @return {Promise.<boolean>}
   */
  public async addBillPaymentMethod(): Promise<boolean> {
    EventBus.emit(EventBus.keys.UPDATE_SETUP_STEP, 'SETUP_SUBSTITUTE_TARIFF');
    if (Stores.substituteData.substitute.debtor !== null) {
      const _v4_regex = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
      const _response = await apolloClient.defaultClient
        .mutate({
          mutation: ADD_PAYMENT_METHOD_BILL,
          variables: {
            debtorID: Stores.substituteData.substitute.debtor.id,
          }
      });
      if (_response.data.addDebtorBillPaymentMethod.match(_v4_regex)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }


  /**
   * confirm a contract
   *
   */
  public async confirmServiceContract(service_id: string): Promise<boolean> {
    const _response = await apolloClient.defaultClient.mutate({
      mutation: UPDATE_SERVICE,
      variables: {
        serviceID: service_id,
        substituteConfirmedContract: true
      }
    });
    return _response.data.updateService;
  }


  /**
   * get a current download link for the license file
   * @return {Promise.<string>} - the url for the download
   */
  public async getLicenseDownloadLink(): Promise<string> {
    await this.fetchSubstitute(false);
    if (Stores.substituteData.substitute.license !== null) {
      return Stores.substituteData.substitute.license.url;
    }
    else {
      return '';
    }
  }

  /**
   * get a current download link for specialization certificate file
   * @return {Promise.<string>} - the url for the download
   */
  public async getCertificateDownloadLink(): Promise<string> {
    await this.fetchSubstitute(false);
    if (Stores.substituteData.substitute.specializations.length > 0) {
      if (Stores.substituteData.substitute.specializations[0].certificate !== null) {
        return Stores.substituteData.substitute.specializations[0].certificate.url;
      }
      else {
        return '';
      }
    }
    else {
      return '';
    }
  }

  /**
   * fetch assignments
   * @return {Promise}
   */
  async fetchAssignments(): Promise<void> {
    Stores.substituteData.loadingAssignments = true;
    const _where = {service: {assignment: { substitute_id: { equals: Stores.substituteData.substitute.id }}}};

    const _mobileAssignments = await apolloClient.defaultClient.query({
      query: MOBILE_SERVICES_QUERY,
      variables: {
        where: _where
      },
      fetchPolicy: 'no-cache'
    });
    const _officeAssignments = await apolloClient.defaultClient.query({
      query: OFFICE_SERVICES_QUERY,
      variables: {
        where: _where
      },
      fetchPolicy: 'no-cache'
    });

    for (const newAssignment of _officeAssignments.data.officeServices) {
      // only push the new service to the store if it is not present yet
      const _assignment = Stores.substituteData.assignments.find((a: Service) => a.id === newAssignment.details.id);
      if (typeof _assignment === 'undefined') {
        const _assignmentReorder: Service = newAssignment.details;
        _assignmentReorder.type = newAssignment.__typename;
        Stores.substituteData.assignments.push(_assignmentReorder);
      }
    }

    for (const newAssignment of _mobileAssignments.data.mobileServices) {
      // only push the new service to the store if it is not present yet
      const _assignment = Stores.substituteData.assignments.find((a: Service) => a.id === newAssignment.details.id);
      if (typeof _assignment === 'undefined') {
        const _assignmentReorder: Service = newAssignment.details;
        _assignmentReorder.type = newAssignment.__typename;
        Stores.substituteData.assignments.push(_assignmentReorder);
      }
    }

    Stores.substituteData.assignments = Sort.arraySortBy(Stores.substituteData.assignments, (service) => [service.start], 1);
    this.setAssignmentsFocusReasons();

    Stores.substituteData.loadingAssignments = false;
    EventBus.emit(EventBus.keys.SUBSTITUTE_LOAD_ASSIGNMENTS_COMPLETE);
  }

  /**
   * add application to storage
   * @return {Promise}
   */
  async addApplication(newApplication: Application): Promise<void> {
    Stores.substituteData.applications.push(newApplication);
  }

  /**
   * add assignment to storage
   * @return {Promise}
   */
    async addAssignment(newAssignment: Service): Promise<void> {
      Stores.substituteData.assignments.push(newAssignment);
      this.setAssignmentsFocusReasons();
    }

  /**
   * add bookmark to storage
   * @return {Promise}
   */
    async addBookmark(newBookmark: Bookmark): Promise<void> {
      Stores.substituteData.bookmark.push(newBookmark);
    }

  /**
   * fetch assignments (legacy)
   * @return {Promise}
   */
  async fetchApplications(): Promise<void> {
    Stores.substituteData.loadingApplications = true;

    const _applications = await apolloClient.defaultClient.query({
      query: QUERY_APPLICATIONS_SUBSTITUTE,
      fetchPolicy: 'no-cache'
    });

    for (const newApplication of _applications.data.profile.substitute.applications) {
      // only push the new application to the store if it is not present yet
      const _application = Stores.substituteData.applications.find((a: Application) => a.details.id === newApplication.details.id);
      if (typeof _application === 'undefined') {
        const _applicationReorder: Application = newApplication;
        _applicationReorder.details.type = newApplication.__typename;
        if (_applicationReorder.details.published === true) {
          Stores.substituteData.applications.push(_applicationReorder);
        }
      }
    }

    Stores.substituteData.applications = Sort.arraySortBy(Stores.substituteData.applications, (service) => [service.start], 1);

    Stores.substituteData.loadingApplications = false;
    EventBus.emit(EventBus.keys.SUBSTITUTE_LOAD_APPLICATIONS_COMPLETE);
  }

  /**
   * fetch bookmarks (legacy; possibly)
   * @return {Promise}
   */
  async fetchBookmarks(): Promise<void> {
    Stores.substituteData.loadingBookmarks = true;
    const _bookmarks = await apolloClient.defaultClient.query({
      query: QUERY_BOOKMARKS_SUBSTITUTE,
      fetchPolicy: 'no-cache'
    });
    for (const newBookmark of _bookmarks.data.profile.substitute.service_bookmarks) {
      // only push the new service to the store if it is not present yet
      const _bookmark = Stores.substituteData.bookmarks.find((b: Bookmark) => b.details.id === newBookmark.details.id);
      if (typeof _bookmark === 'undefined') {
        const _bookmarkReorder: Bookmark = newBookmark;
        _bookmarkReorder.details.type = newBookmark.__typename;
        if (_bookmarkReorder.details.published === true) {
          Stores.substituteData.bookmarks.push(_bookmarkReorder);
        }
      }
    }

    Stores.substituteData.bookmarks = Sort.arraySortBy(Stores.substituteData.bookmarks, (bookmark) => [bookmark.details.start], 1);

    Stores.substituteData.loadingBookmarks = false;
    EventBus.emit(EventBus.keys.SUBSTITUTE_LOAD_BOOKMARKS_COMPLETE);
  }

  /**
   * fetch the full set of information needed for the substitute service management page
   * @param {string} service_id - service UUID
   * @return {Promise.<Service>}
  */
  public async fetchServiceManagementService(service_id: string): Promise<Service> {
    const _response = await apolloClient.defaultClient.query({
      query: QUERY_SERVICE_SERVICEMANAGEMENT_SUBSTITUTE,
      variables: {
        serviceID: service_id
      },
      fetchPolicy: 'no-cache'
    });
    const _service: Service = _response.data.listServices[0].details;
    _service.type = _response.data.listServices[0].__typename;
    return _service;
  }

  /**
   * get concluded services
   * @return {Array.<Service>}
   */
  public getConcludedServices(count: number|null = 4): Service[] {
    const _concludedServices: Service[] = [];
    const _services = Stores.substituteData.assignments;
    for (const service of _services) {
      if (service.concluded) {
        _concludedServices.push(service);
      }
    }
    const _count = count ? count : _concludedServices.length;
    return _concludedServices.slice(0, _count);
  }

  /**
   * get assigned services (exclude concluded services)
   * @return {Array.<Service>}
   */
  public getAssignedServicesWithoutConcluded(count: number|null = 4): Service[] {
    const _assignedServices: Service[] = [];
    const _services = Stores.substituteData.assignments;
    for (const service of _services) {
      if (!service.concluded) {
        _assignedServices.push(service);
      }
    }
    const _count = count ? count : _assignedServices.length;
    return _assignedServices.slice(0, _count);
  }

  /**
   * get bookmarks (filtered for services that the substitute already has applied for)
   * @return {Array.<Service>}
   */
  public getBookmarksWithoutAppliedServices(count: number|null = 4): Service[] {
    const _serviceBookmarks: Service[] = [];
    const _bookmarks: Service[] = this.getBookmarksAsServices(null);
    const _applications: Service[] = this.getApplicationsAsServices(null);

    for (const bookmark of _bookmarks) {
      let hasApplied = false;
      for (const application of _applications) {
        if (bookmark.id === application.id) {
          hasApplied = true;
        }
      };
      // We will not show applied services in bookmarks list
      if (!hasApplied) {
        _serviceBookmarks.push(bookmark);
      }
    }

    const _count = count ? count : _serviceBookmarks.length;
    return _serviceBookmarks.slice(0, _count);
  }

  /**
   * get bookmarks (filtered for services that the substitute already has applied for)
   * @return {Array.<Bookmark>}
   */
  public getRawBookmarksWithoutAppliedServices(count: number|null = 4): Bookmark[] {
    const _serviceBookmarks: Bookmark[] = [];
    const _bookmarks: Bookmark[] = this.getBookmarksAsBookmarks(null);
    const _applications: Service[] = this.getApplicationsAsServices(null);

    for (const bookmark of _bookmarks) {
      let hasApplied = false;
      for (const application of _applications) {
        if (bookmark.details.id === application.id) {
          hasApplied = true;
        }
      };

      // We will not show applied services in bookmarks list
      if (!hasApplied) {
        _serviceBookmarks.push(bookmark);
      }
    }

    const _count = count ? count : _serviceBookmarks.length;
    return _serviceBookmarks.slice(0, _count);
  }

  /**
   * Sets possible focus reasons for all assignments present in the store
   * @return {void}
   */
  public setAssignmentsFocusReasons(): void {
    Stores.substituteData.generatingSubstituteFocusServices = true;

    const _now = new Date();
    Stores.substituteData.focusAssignments = [];
    for (const _service of Stores.substituteData.assignments) {
      const _serviceStart = new Date(_service.start);
      const _serviceEnd = new Date(_service.start);
      const _reasons = [];

      if (_serviceStart >= _now) {
        if (_service.substituteConfirmedContractOn === null) {
          _reasons.push($t('DASHBOARD.FOCUS_SERVICES.RIBBON.SUBSTITUTE.CONTRACT_NOT_CONFIRMED'));
        }
      }
      if (_serviceEnd < _now) {
        if (
          _service.privatePatientCount === null ||
          _service.necropsyCount === null ||
          _service.stateInsuredPatientCount === null ||
          _service.consultationViaTelephoneCount === null) {
            _reasons.push($t('DASHBOARD.FOCUS_SERVICES.RIBBON.SUBSTITUTE.PATIENTS_COUNT'));
        }
      }
      _service.focusReasons = _reasons;
      if (_reasons.length !== 0) {
        Stores.substituteData.focusAssignments.push(_service);
      }
    }

    Stores.substituteData.focusAssignments = Sort.arraySortBy(Stores.substituteData.focusAssignments, (service) => [service.start], 1);
    Stores.substituteData.generatingSubstituteFocusServices = false;
  }
}

export default new Substitute();
