export default {
  NAVIGATION: {
    SERVICES: 'Dienste',
    SERVICE_SUBMISSIONS: 'Dienst Submissions',
    ALL_INCLUSIVE_SERVICES: 'Rundum Sorglos Dienste',
    TIER_SERVICES: 'Dienste nach Dringlichkeit',
    SERVICEAREAS: 'Dienstgebiete',
    USERS: 'Nutzer',
    INSURANCES: 'Versicherungen',
    ACCOUNTING: 'Buchhaltung',
    REPORTING: 'Reporting',
  },
  EXCEPTIONS: {
    HEADLINE: 'Es ist ein Fehler aufgetreten.',
    DESCRIPTION: 'Der folgende Fehler ist aufgetreten: ',
    CLOSE: 'Schließen',
  },
  EVENT_LOGS: {
    HEADING: 'Event Log',
    TOGGLE: 'Event Log',
    EVENT_TYPES: {
      user_registered: 'Nutzer hat sich registriert',
      update_profile: 'Profil aktualisiert',
      partner_role_request: 'Rolle "Kunde" festgelegt',
      substitute_role_request: 'Rolle "Vertreter" festgelegt',
      update_subsitute: 'Vertreterdaten aktualisiert',
      create_application: 'Bewerbung hinzugefügt',
      create_assignment: 'Dienst vergeben',
      revoke_application: 'Bewerbung gelöscht',
      revoke_assignment: 'Dienstzuweisung gelöscht',
      message_read: 'Nachricht gelesen',
      create_office_service: 'Sitzdienst angelegt',
      create_mobile_service: 'Fahrdienst angelegt',
    }
  },
  TAB_LABELS: {
    OPERATOR_SERVICES: 'Dienstverwaltung',
    OPERATOR_SERVICESUBMISSIONS: 'Dienst Submissions',
    OPERATOR_ALLINCLUSIVE_SERVICES: 'Rundum-Sorglos Dienste',
    OPERATOR_TIER_SERVICES: 'Dienste nach Dringlichkeit',
    OPERATOR_SERVICEAREAS: 'Dienstgebiete',
    OPERATOR_SINGLE_SERVICEAREA: 'Dienstgebiet',
    OPERATOR_USERS: 'Nutzerverwaltung',
    OPERATOR_INSURANCES: 'Versicherungen',
    OPERATOR_ACCOUNTING: 'Buchhaltung',
    OPERATOR_REPORTING: 'Reporting',
    OPERATOR_SINGLE_USER: 'Nutzer',
    OPERATOR_SINGLE_SERVICE: 'Dienst',
  },
  CLOSE_TAB: 'Tab schließen',
  TABLE: {
    ROWS_PER_PAGE: 'Zeilen pro Seite',
    NO_DATA: 'Keine Daten geladen',
    SEPARATOR: 'von',
  },
  CONFIRM: {
    YES: 'Ja',
    NO: 'Nein',
  }
};
