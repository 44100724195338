import { ApolloClient, ApolloLink, HttpOptions, InMemoryCache, Operation } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createUploadLink } from 'apollo-upload-client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { ID_TOKEN } from '@/data/cognito';
import CaptureException from '@/services/CaptureException';

import Debug from '@/utils/Debug';
import apiConfig from '@/configs/api';

// http options for apollo links
const httpOptions: HttpOptions = {
  uri: apiConfig.apollo.uri,
};

// file upload link
const uploadLink: ApolloLink = createUploadLink({
  ...httpOptions,
});

// batch http link for non-uploads
const batchHttpLink: BatchHttpLink = new BatchHttpLink({
  ...httpOptions,
  batchMax: 5, // No more than 5 operations per batch
  batchInterval: 20, // Wait no more than 20ms after first batched operation
});

// default http link by decision
const httpLink: ApolloLink = ApolloLink.split(
  (operation: Operation) => operation.getContext().hasUpload,
  uploadLink,
  batchHttpLink
);

// cache strategy
const cache: InMemoryCache = new InMemoryCache({
  resultCaching: false,
});

// retry link instance
const retryLink: RetryLink = new RetryLink({
  delay: {
    initial: 2,
  },
  attempts: {
    max: 2,
  },
});

// error link
const errorLink: ApolloLink = onError(({ graphQLErrors, networkError }): void => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }): void => {
      CaptureException.send(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      Debug.toConsole(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

// auth link
const authLink: ApolloLink = setContext((_, { headers }): {headers: any} => {
  // get the authentication token from local storage if it exists
  const token: string|null = localStorage.getItem(ID_TOKEN);

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
    },
  };
});

// client instance
const apolloClient: ApolloClient<any> = new ApolloClient({
  link: ApolloLink.from([
    retryLink,
    errorLink,
    authLink,
    httpLink,
  ]),
  cache,
});

// vue apollo provider
export default createApolloProvider({
  defaultClient: apolloClient,
});
