export default {
  SERVICE_ADVERTISEMENT: {
    SUBJECT: 'KV-Dienst Vertretung gesucht: ',
    PREAMBLE: 'wir suchen eine KV-Dienst Vertretung für den Bereich ',
    BODY: [
      'Dienstart: ',
      'Dienstort: ',
      'Dienstdauer: ',
      'Dienstbeginn: ',
      'Dienstende: ',
      'Honorar: ',
      'Notfallscheinbeteiligung: ',
      'Die genaue leistungsabhängige Vergütung entnehmen Sie bitte dem Honorarvertrag.',
      'Achtung: Bitte besorgen Sie sich vor Dienstantritt eigenständig die Totenscheine für das jeweilige Bundesland!',
      'Ein Notdienstkoffer wird bei Bedarf gestellt.',
    ],
    FOOTER: 'Bei Interesse bitten wir um zeitnahe Rückmeldung oder bewerben Sie sich direkt auf unserem Marktplatz: ',
  }
};
